import { toast } from "react-toastify";

export const baseHost =
  `${process.env.REACT_APP_BASEURL}`;

export async function httpRequest({ url, data, method, baseUrl }) {
    const PrepareData = (Data, type = "json")  => {
        //change default based on app's api default content type
        if (type === "json") {
          return JSON.stringify(Data);
        } else if (type === "multipart") {
          const formData = new FormData();
          Object.keys(Data).forEach((e) => {
            formData.append(e, Data[e]);
          });
          return formData;
        }
      }
    console.log("data", data);
    if (!baseUrl) baseUrl = baseHost + "/";
    let host = baseUrl;
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    if (data?._headers) {
      requestOptions.headers = { ...requestOptions.headers, ...data._headers };
      delete data._headers;
    } else {
      requestOptions.headers = { ...requestOptions.headers };
    }
    console.log("requestOptions", requestOptions);
  
    if (data) requestOptions["body"] = PrepareData(data, "json");
  
    return await fetch(`${host}${url}`, requestOptions)
      .then(async (res) => {
        return await res.json();
      })
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        // raiseError(err)
        return {
          status: false,
          error: "An error occurred, please try again.",
          errorObject: err.toString(),
        };
      });
}

export function showToast({ type, message }) {
    if (type === "error") toast.error(message);
    if (type === "success") toast.success(message);
    return;
}

export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function saveByteArray(reportName, byte, extension = ".pdf") {
  // var blob = new Blob([byte], {type: applicationType});
  // var link = document.createElement('a');
  // link.href = window.URL.createObjectURL(blob);
  // var fileName = reportName;
  // link.download = fileName;
  // link.click();
  if (!extension.startsWith(".")) {
    extension = ".";
  }

  const url = window.URL.createObjectURL(new Blob([byte]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${reportName}${new Date().toLocaleTimeString()}${extension}`
  ); //or any other extension
  document.body.appendChild(link);
  link.click();
}
