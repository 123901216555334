import { useState } from "react";
import Swal from "sweetalert2";
import { urls } from "../urls";
import { base64ToArrayBuffer, httpRequest, saveByteArray, showToast } from "../utils";
import Form from "./form";

export function FormContainer() {
    const defaultOptions = "Water Heater,Air Conditioner,Sumo Pump,Washing Machine,Microwave Oven,Electric Cooker,Refrigerator,Freezer,Electric Iron,TV,Lighting Points,Fan";
    const [image, setImage] = useState(undefined);
    const [accountData, setAccountData] = useState(undefined);
    const [bldOptions, setBLDOptions] = useState(defaultOptions.split(","));
    const [receipt, setReceipt] = useState("");

    function encodeImageFileAsURL(filesSelected) {
        let fileToLoad;
        if (filesSelected.length > 0) {
            fileToLoad = filesSelected[0];
        } else {
            fileToLoad = filesSelected;
        }
        const fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
            var srcData = fileLoadedEvent.target.result; // <--- data: base64
            setImage(srcData);
        }
        fileReader.readAsDataURL(fileToLoad);
    }

    const onSubmit = async (payload) => {
        payload.idDocument = image;
        console.log('payload', payload);
        // send api request to
        // 
        const response = await httpRequest({ url: urls.customerInfo, data: payload, method: 'post' });
        if (response && response.status) {
            showToast({ type: 'success', message: response.message || 'Form submitted' });
            console.log('response.data', response.data);
            setReceipt(response.data);
            return response.data;
        } else {
            showToast({ type: 'error', message: response.message || 'An error occurred while submitting form.' });
            return undefined;
        }
        
        // Swal.fire({
        //     title: 'Submit Form',
        //     icon: 'info',
        //     html:
        //         'Please verify if you want to proceed or re-verify form data.',
        //     showCloseButton: true,
        //     showCancelButton: true,
        //     focusConfirm: false,
        //     confirmButtonText:
        //         'Submit',
        //     confirmButtonAriaLabel: 'Submit',
        //     cancelButtonText:
        //         'Review',
        //     cancelButtonAriaLabel: 'Cancel'
        // }).then(async (result) => {
        //     if (result) {
        //         const response = await httpRequest({ url: urls.customerInfo, data: payload, method: 'post' });
        //         if (response && response.status) {
        //             showToast({ type: 'success', message: response.message || 'Form submitted' });
        //             console.log('response.data', response.data);
        //             setReceipt(response.data);
        //         } else {
        //             showToast({ type: 'error', message: response.message || 'An error occurred while submitting form.' });
        //         }
        //     }
        // });
    };
    const searchForCustomerAccount = async (payload) => {
        console.log('payload', payload);
        const response = await httpRequest({ url: urls.findCustomer + '?userId=' + payload, data: undefined, method: 'get' });
        if (response && response.status) {
            setAccountData(response.data);
            showToast({ type: 'success', message: response.message || 'Customer account valid.' });
            return response.data;
        }
        else {
            showToast({ type: 'error', message: response.message || 'Customer account not valid.' });
            return undefined;
        }
    }
    const onImageSelected = (e) => {
        encodeImageFileAsURL(e.target.files);
    }
    const onGeneratePDF = (referenceId, pdfData) => {
        saveByteArray(
            `${referenceId}_${pdfData.fileName}`,
            base64ToArrayBuffer(pdfData.base64),
            pdfData.extension
          );
    }
    
    const onDownloadBlankForm = async () => {
        console.log('here');
        showToast({ type: 'success', message: 'Generating pdf' });
        const response = await httpRequest({ url: urls.generateBlankForm, data: undefined, method: 'get' });
        if (response && response.status) {
            saveByteArray(
                `${response.data.fileName}`,
                base64ToArrayBuffer(response.data.base64),
                response.data.extension
              );
            showToast({ type: 'success', message: response.message || 'Form generated.' });
            return response.data;
        }
        else {
            showToast({ type: 'error', message: response.message || 'Form generation failed.' });
            return undefined;
        }
    }
    
    return <Form
        onSubmit={onSubmit}
        searchForCustomerAccount={searchForCustomerAccount}
        onImageSelected={onImageSelected}
        bldOptions={bldOptions}
        receipt={receipt}
        onGeneratePDF={onGeneratePDF}
        onDownloadBlankForm={onDownloadBlankForm}
    // accountData={accountData} 
    />
}