import { FormContainer } from './screen/form-container';

function App() {
  console.log("process.env", process.env);
  return (
    <>
      <FormContainer />
    </>
  );
}

export default App;
