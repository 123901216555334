import React, { useState } from "react";
import Swal from "sweetalert2";
import { generateKey } from "../utils";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
// import Multiselect from 'multiselect-react-dropdown';

String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

function Form({ onSubmit, searchForCustomerAccount, onImageSelected, bldOptions, receipt, onGeneratePDF, onDownloadBlankForm }) {
	const [name, setName] = useState();
	// const [image, setImg] = useState();
	const [phone, setPhone] = useState();
	const [alternatePhone, setAlternatePhone] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [additionalAddress, setAdditionalAddress] = useState('');
	const [landmark, setLandmark] = useState('');
	const [district, setDistrict] = useState();
	const [state, setState] = useState();
	const [lga, setLga] = useState();
	const [town, setTown] = useState();
	const [premiseType, setPremiseType] = useState();
	const [noOfFloors, setNoOfFloors] = useState();
	const [noOfFlats, setNoOfFlats] = useState();
	const [useOfPremise, setUseOfPremise] = useState();
	const [typeOfRequest, setTypeOfRequest] = useState();
	const [meterType, setMeterType] = useState();
	const [oldMeterNo, setOldMeterNo] = useState();
	const [currentBillingInfo, setCurrentBillingInfo] = useState();
	const [meterProgram, setMeterProgram] = useState();
	const [meterRequestBy, setMeterRequestBy] = useState();
	const [accountNo, setAccountNo] = useState();
	const [bld, setBLD] = useState([]);
	const [bldOption, setBldOption] = useState([]);
	const [referenceId, setReferenceId] = useState(receipt);
	const [pdf, setPDF] = useState(undefined);
	const [nameOnBill, setnameOnBill] = useState('');
	// console.log('referenceId', referenceId);

	const _onSubmit = () => {
		console.log('bldOption', bld, bldOption);
		//return;
		const payload = {
			"fullName": `${name.replaceAll("'", "".replaceAll('"', ''))}`,
			"phone": phone,
			"email": email,
			"account_no": accountNo,
			"nameOnBill": `${nameOnBill.replaceAll("'", "".replaceAll('"', ''))}`,
			"address": `${address.replaceAll("'", "".replaceAll('"', ''))}`,
			"addressLandmark": `${landmark.replaceAll("'", "".replaceAll('"', ''))}`,
			"district": district,
			"state": state,
			"lga": lga,
			"town": town,
			"typeOfBuilding": premiseType,
			"noOfFloors": noOfFloors,
			"noOfFlats": noOfFlats,
			"useOfPremise": useOfPremise,
			"typeOfRequest": typeOfRequest,
			"meterTypeRequest": meterType,
			"currentBillingInfo": currentBillingInfo,
			"meterProgramType": meterProgram,
			"meterRequest": meterRequestBy,
			"additionalAddress": additionalAddress,
			"bld": bld.map(x => {
				delete x.id;
				delete x.identifier;
				return `${x.name} (${x.value})`;
			}),
			"alternatePhone": alternatePhone,
			oldMeterNo: oldMeterNo,
		};
		Swal.fire({
			title: 'Submit Form',
			icon: 'info',
			html:
				'Please verify if you want to proceed or re-verify form data.',
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			confirmButtonText:
				'Submit',
			confirmButtonAriaLabel: 'Submit',
			cancelButtonText:
				'Review',
			cancelButtonAriaLabel: 'Cancel'
		}).then(async (result) => {
			if (result) {
				onSubmit(payload).then(result => {
					console.log('result', result)
					setReferenceId(result.identifier);
					setPDF(result.pdf);
					// clear form fields
				});
			}
		});
		console.log('-- payload --', payload)
		// return;
		// onSubmit(payload);
	}

	const _searchForCustomerAccount = () => {
		setReferenceId('');
		searchForCustomerAccount(accountNo).then(result => {
			console.log('result', result);
			if (!result) {
				setAccountNo('');
			} else {
				mapInfoToField(result);
			}
		});
	}

	const mapInfoToField = (payload) => {
		// setName(payload.name);
		setState(payload.cus_state);
		setAddress(payload.street);
		setDistrict(payload.district_name);
	}

	const imgChange = (e) => {

		e.preventDefault();
		onImageSelected(e);
	}
	const onNameChange = (e) => {
		setName(e.target.value);
	}
	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	}
	const onAlternatePhoneChange = (e) => {
		setAlternatePhone(e.target.value);
	}
	const onEmailChange = (e) => {
		setEmail(e.target.value);
	}
	const onAccountNumberChange = (e) => {
		setAccountNo(e.target.value);
	}

	const onAddressChange = (e) => {
		setAddress(e.target.value);
	}
	const onAdditionalAddressChange = (e) => {
		setAdditionalAddress(e.target.value);
	}
	const onLandmarkChange = (e) => {
		setLandmark(e.target.value);
	}
	const onDistrictChange = (e) => {
		setDistrict(e.target.value);
	}
	const onStateChange = (e) => {
		setState(e.target.value);
	}
	const onLGAchange = (e) => {
		setLga(e.target.value);
	}
	const onTownChange = (e) => {
		setTown(e.target.value);
	}
	const onPremiseTypeChange = (e) => {
		setPremiseType(e.target.value);
	}
	const onNoOfFloorsChange = (e) => {
		setNoOfFloors(e.target.value);
	}
	const onNoOfFlatsChange = (e) => {
		setNoOfFlats(e.target.value);
	}
	const onUseOfPremiseChange = (e) => {
		console.log('onUseOfPremiseChange', e);
		setUseOfPremise(e.target.value);
	}
	const onTypeOfRequestChange = (e) => {
		setTypeOfRequest(e.target.value);
	}
	const onMeterTypeChange = (e) => {
		setMeterType(e.target.value);
	};
	const onOldMeterNoChange = (e) => {
		setOldMeterNo(e.target.value);
	};
	const onCurrentBillingInfoChange = (e) => {
		setCurrentBillingInfo(e.target.value);
	};
	const onMeterProgramType = (e) => {
		setMeterProgram(e.target.value);
	}
	const onMeterRequestByChange = (e) => {
		setMeterRequestBy(e.target.value);
	}
	const onAccountNoChange = (e) => {
		setAccountNo(e.target.value);
	};
	const onAddExtraLoad = (e) => {
		e.preventDefault();
		setBldOption([...bldOption, "Please specify"]);
	}

	const generatePDF = async () => {
		onGeneratePDF(referenceId,pdf);
		return;
		const result = await processPDF();
		result.save(referenceId);
	};

	const processPDF = async () => {
		const domElement = document.getElementById("summary");
		const width = domElement.offsetWidth;
		const height = domElement.offsetHeight;
		const title = referenceId;
		return await html2canvas(domElement, {
			onclone: (document) => {
				// document.getElementById("generate-pdf").style.visibility = "hidden";
				// document.getElementById("handle-print").style.visibility = "hidden";
				const items = document.querySelectorAll('.form-control');
				items.forEach(item => {
					item.style.backgroundImage = "none";
				})
			},
		}).then((canvas) => {
			const widthInMM = canvas.width * 0.22;
			const heightInMM = canvas.height * 0.45;

			// const widthInMM = 264;
			// const heightInMM = canvas.height * 0.3;

			const imgData = canvas.toDataURL("image/png");
			//   const doc = new jsPdf("p", "mm", "a4");
			const doc = new jsPdf("l", "mm", [widthInMM, heightInMM]);
			// const doc = new jsPdf({
			// 	orientation: "landscape",
			// 	unit: "in",
			// 	format: [2, 1]
			// });
			doc.setProperties({
				title: title,
			});
			//delete the first page
			doc.deletePage(1);
			//recreate a new page with the new measurements
			doc.addPage([widthInMM, heightInMM], "l");
			// add image to the page
			doc.addImage(imgData, "PNG", 0, 0, widthInMM, heightInMM);
			return doc;
		});
	};

	const onDownloadForm = (e) => {
		onDownloadBlankForm();
		e.preventDefault();
		
	}

	return (
		<>
			<div id="mainDiv" className="image-container set-full-height" style={{ backgroundColor: "#03a9f4 !important" }}>
				<a href="index.html">
					<div className="logo-container" style={{ position: "fixed", zIndex: "3", top: "20px", left: "20px" }}>
						<div className="logos" style={{ padding: "3px", width: "70px", height: "40px", borderRadius: "4px", boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)" }}>
							{/* <img 
								width="60" 
								src={require(`./../assets/img/${process.env.REACT_APP_APP_LOGO
									? process.env.REACT_APP_APP_LOGO
									: "argon-react.png"
								}`).default} 
								style={{  width: "70px", height: "60px" }}
							/> */}
							<img
								width="60"
								src={`assets/img/${process.env.REACT_APP_APP_LOGO}`}
								style={{ width: "70px", height: "60px" }}
							/>
						</div>
					</div>
				</a>

				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="wizard-container">
								<div className="card wizard-card" data-color="red" id="wizardProfile">
									<form action="#" method=""><div className="wizard-header">
										<h3 className="wizard-title">
											MAP Meter Request
										</h3>
										<h5>This form lets you apply for a MAP meter.</h5>
										<h6><a style={{ cursor: 'pointer' }} onClick={(e) => onDownloadForm(e)}>You can download a blank form by clicking here.</a></h6>
									</div>
										<div className="wizard-navigation">
											<ul>
												<li><a ng-click="createAccountsArray()" href="#about" data-toggle="tab">Applicant</a></li>
												<li><a ng-click="createAccountsArray()" href="#account" data-toggle="tab">Account</a></li>
												<li><a ng-click="createAccountsArray()" href="#address" data-toggle="tab">Apartment</a></li>
												<li><a ng-click="createAccountsArray()" href="#summary" data-toggle="tab">Summary</a></li>
											</ul>
										</div>

										<div className="tab-content">
											<div className="tab-pane" id="about">
												<div className="row">
													<h4 className="info-text">
														<small>(Enter your phone number and click the send button)</small>
													</h4>
													<div className="col-sm-4 col-sm-offset-1">
														<div className="picture-container">
															<div className="picture">
																<img src="assets/img/default-avatar.png" className="picture-src" id="wizardPicturePreview" alt='user ID avatar' />
																<input type="file" id="wizard-picture" name="avatar" accept=".png,.JPEG,.jpg" onChange={imgChange} />
															</div>
															<h6 style={{ color: "#03a9f4", fontWeight: "bolder" }}>Please Click Here to Upload  Either Your National ID, Voter's Card,
																Driver's License Or International Passport <br /><small>(Only image files of not more than 2mb allowed)</small></h6>
														</div>
													</div>

													<div className="col-sm-6">
														<div className="input-group">
															<span className="input-group-addon">
																<i className="material-icons">record_voice_over</i>
																<i className="material-icons">add</i>
															</span>
															<div className="form-group label-floating">
																<label className="control-label">Your full name <small>(Surname first)</small></label>
																<input ng-model="request.customerName" name="name" type="text" className="form-control" onChange={onNameChange} />
															</div>
														</div>


														<div className="row">
															<div className="col-sm-6">
																<div className="input-group">
																	<span className="input-group-addon">
																		<i className="material-icons">phone</i>
																	</span>
																	<div className="form-group label-floating">
																		<label ng-className="{'value-entered': request.phone}" className="control-label">Phone number <small>(required)</small></label>
																		<input ng-model="request.phone" id="phone" name="phone" type="text" className="form-control" onChange={onPhoneChange} value={phone} />
																	</div>
																</div>
															</div>
															<div className="col-sm-6">
																<div className="form-group label-floating">
																	<label className="control-label">Whatsapp Phone Number</label>
																	<input name="alternatePhone" type="text" ng-model="request.altPhone" className="form-control" onChange={onAlternatePhoneChange} value={alternatePhone} />
																</div>
															</div>
														</div>

														<div className="input-group">
															<span className="input-group-addon">
																<i className="material-icons">email</i>
															</span>
															<div className="form-group label-floating">
																<label className="control-label">Email</label>
																<input name="email" ng-model="request.customerEmail" type="email" className="form-control" onChange={onEmailChange} value={email} />
															</div>
														</div>
													</div>
												</div>
												<h5 className="info-text" style={{ color: "red", fontSize: "17px", fontWeight: "bold" }}>Please note that if you provide an invalid or inactive phone number, {process.env.REACT_APP_APPLICATION_TITLE} will not be able to process your meter application.</h5>
											</div>
											<div className="tab-pane" id="account">
												<h4 className="info-text"> Now let's validate your {process.env.REACT_APP_APPLICATION_TITLE} account<small>(Enter your account number and click the search button)</small></h4>
												<div className="row" style={{ margin: "5px" }}>
												</div>
												<h5 className="info-text" style={{ color: "red", fontSize: "17px", fontWeight: "bold" }}>
													This form is strictly for one meter request. For multiple meters request, please kindly fill the form multiple times.
												</h5>
												<div className="row">
													<div className="col-sm-12 col-sm-offset-0">

														<div className="col-sm-12">
															<div className="input-group">
																{/* <!--style="max-height: 50px"text-center--> */}
																<div className="form-group">
																	<label className="control-label">Account number <small>(required)</small></label>
																	<input autofocus ng-model="accountNumber" id="accountno" name="accountno" type="text"
																		className="form-control active" onChange={onAccountNumberChange} value={accountNo} />
																</div>
																<div className="input-group-btn">
																	<button ng-disabled="!accountNumber" id="search" className="btn btn-xs btn-danger" onClick={() => _searchForCustomerAccount()} type="button">
																		Search <i className="material-icons">search</i>
																	</button>
																</div>
															</div>
														</div>

														<div className="col-sm-4">
															<div className="form-group">
																<label className="control-label">Name on your bill</label>
																<input readonly ng-model="request.nameOnBill" id="accountname" name="accountname" type="text" className="form-control"  onChange={(e) => setnameOnBill(e.target.value)} value={nameOnBill} />
															</div>
														</div>

														<div className="col-sm-4">
															<div className="form-group">
																<label className="control-label">Address</label>
																<input ng-model="request.adddress" id="address1" name="address1" type="text" className="form-control" onChange={onAddressChange} value={address} />
															</div>
														</div>

														<div className="col-sm-4">
															<div className="form-group">
																<label className="control-label">Additional Address Information</label>
																<input ng-model="request.secondAddress" id="address2" name="address2" type="text" className="form-control" onChange={onAdditionalAddressChange} value={additionalAddress} />
															</div>
														</div>

														<div className="col-sm-4">
															<div className="form-group">
																<label className="control-label">Address Landmark</label>
																<input ng-model="request.addressLandMark" id="landmark" name="landmark" type="text" className="form-control" onChange={onLandmarkChange} value={landmark} />
																<small>
																	ADDRESS LANDMARK (Use “Opposite”, “Near”, “Around” either school, church, supermarket name nearest to your building).
																</small>
															</div>
														</div>

														<div className="col-sm-4">
															<div className="form-group">
																<label className="control-label">District</label>
																<input readonly ng-model="request.district" id="district" name="district" type="text" className="form-control" onChange={onDistrictChange} value={district} />
															</div>
														</div>
														<div className="col-sm-4">
															<div className="form-group">
																<label className="control-label">State <small>(required)</small> </label>
																<select id="state"
																	name="state"
																	type="text"
																	style={{ width: "100%" }}
																	//  
																	onChange={(onStateChange)}
																	value={state}
																>
																	<option disabled="" selected="">Select one</option>
																	<option value="Abia">Abia</option>
																</select>
															</div>
														</div>
														<div className="clearfix"></div>

														<div className="col-sm-6">
															<div className="form-group">
																<label className="control-label">Local Government Area <small>(requred)</small></label>
																<select
																	id="lga"
																	name="lga"
																	type="text"
																	style={{ width: "100%" }}
																	onChange={onLGAchange} value={lga}
																>
																	<option disabled="" selected="">Select one</option>
																	<option value="Aba North">Aba North</option>
																	<option value="Aba South">Aba South</option>
																	<option value="Arochukwu">Arochukwu</option>
																	<option value="Bende">Bende</option>
																	<option value="Ikwuano">Ikwuano</option>
																	<option value="IsialaNgwa North">Isiala Ngwa North</option>
																	<option value="IsialaNgwa South">Isiala Ngwa South</option>
																	<option value="Isuikwuato">Isuikwuato</option>
																	<option value="Obi Ngwa">Obi Ngwa</option>
																	<option value="Ohafia">Ohafia</option>
																	<option value="Osisioma Ngwa">Osisioma Ngwa</option>
																	<option value="Ugwunagbo">Ugwunagbo</option>
																	<option value="Ukwa East">Ukwa East</option>
																	<option value="Ukwa West">Ukwa West</option>
																	<option value="Umuahia North">Umuahia North</option>
																	<option value="Umuahia South">Umuahia South</option>
																	<option value="Umu Nneochi">Umu Nneochi</option>
																</select>
															</div>
														</div>

														<div className="col-sm-6">
															<div className="form-group">
																<label className="control-label">Town/Village</label>
																<input ng-model="request.villageTown" id="town" name="town" type="text" className="form-control" onChange={onTownChange} value={town} />
															</div>
														</div>
													</div>
												</div>

												{/* <!--<div className="row">-->
											<!--<div className="col-sm-12 col-sm-offset-0">-->
												<!---->
											<!--</div>-->
										<!--</div>--> */}
											</div>
											<div className="tab-pane" id="address">
												<div className="row">
													<div className="col-sm-12">
														<h4 className="info-text">Give us details about your apartment</h4>
													</div>
													<div className="col-sm-4">
														<div className="form-group">
															<label className="control-label">Type of premises/building</label>
															{/* <!--<br/>label-floating--> */}
															<select name="premiseType" ng-model="request.typesOfPremise" style={{ width: "100%" }} onChange={onPremiseTypeChange} value={premiseType}>
																<option disabled selected>Select one</option>
																<option value="Bungalow">Bungalow</option>
																<option value="Duplex">Duplex</option>
																<option value="Flat">Flat</option>
																<option value="Shop">Shop</option>
																<option value="Church">Church</option>
																<option value="School">School</option>
																<option value="Hospital">Hospital</option>
																<option value="Others">Others</option>
															</select>
														</div>
														<small>
															<input
																placeholder="If others is selected, Please specify"
																onChange={(e) => onPremiseTypeChange(e)}
																className="form-control"
															/>
														</small>
													</div>
													<div className="col-sm-4" id="useofpremise">
														<div className="form-group">
															<label className="control-label">Use of premises <small>(required)</small></label>
															<select name="premises" style={{ width: "100%" }} onChange={(e) => {
																console.log('1 onUseOfPremiseChange', e);
																onUseOfPremiseChange(e);
															}} value={useOfPremise}>
																<option disabled="" selected="">Select one</option>
																<option value="Residential"> Residential </option>
																<option value="Commercial"> Commercial </option>
																<option value="Industrial"> Industrial </option>
																<option value="Special"> Special (Church, School, Hospital...) </option>
															</select>
														</div>
													</div>
													<div className="col-sm-4" id="typeofrequest">
														<div className="form-group">
															<label className="control-label">Type of Request<small>(required)</small></label>
															<select name="requestType" ng-model="request.typeOfConnection"
																style={{ width: "100%" }} onChange={onTypeOfRequestChange} value={typeOfRequest}>
																<option disabled="" selected="">Select one</option>
																<option value="Meter Request (Existing Unmetered Customer)">Meter Request (Existing Unmetered Customer)</option>
																<option value="Meter Replacement">Meter Replacement</option>
																<option value="New Connection">New Connection</option>
															</select>
														</div>
													</div>
													<div className="col-sm-4" id="typerequested">
														<div className="form-group">
															<label className="control-label">Meter Type Requested<small>(required)</small></label>
															<select ng-model="request.existingConnection" name="conType" style={{ width: "100%" }} onChange={onMeterTypeChange} value={meterType}>
																<option disabled="" selected="">Select one</option>
																<option value="1-Phase">1-Phase</option>
																<option value="3-Phase">3-Phase</option>
																{/* <option value="LVMD">LVMD</option>
																<option value="HVMD">HVMD</option> */}
															</select>
														</div>
													</div>

													<div className="col-sm-3" ng-show="request.typeOfConnection === 'Meter Replacement'">
														<div className="form-group">
															<label className="control-label">Old meter number</label>
															<input ng-model="request.oldMeterNumber" type="text" className="form-control" onChange={onOldMeterNoChange} value={oldMeterNo} />
														</div>
													</div>

													<div className="col-sm-4">
														<div className="form-group">
															<label className="control-label">Current Billing Information</label>
															<select ng-model="request.currentBillInformation" name="billing" style={{ width: "100%" }} onChange={onCurrentBillingInfoChange} value={currentBillingInfo}>
																<option disabled="" selected="">Select one</option>
																<option value="Estimate">Estimate</option>
																<option value="Fixed">Fixed</option>
																<option value="Fixed">Meter Reading</option>
															</select>
														</div>
													</div>
													<div className="col-sm-4">
														<div className="form-group">
															<label className="control-label">Meter Program Type<small>(required)</small></label>
															<select ng-model="request.programType" name="programtype" style={{ width: "100%" }} required onChange={onMeterProgramType} value={meterProgram}>
																<option disabled="" selected="">Select one</option>
																<option value="Map">Map</option>
															</select>
														</div>
													</div>
													<div className="col-sm-4">
														<div className="form-group">
															<label className="control-label">Meter request made by</label>
															<select name="requestedBy" ng-model="request.applicant" style={{ width: "100%" }} onChange={onMeterRequestByChange} value={meterRequestBy}>
																<option disabled="" selected="">Select one</option>
																<option value="Landlord">Landlord</option>
																<option value="Tenant">Tenant</option>
															</select>
														</div>
													</div>

													{/* <h5 className="info-text" style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}>Click the Add more button if you are applying for more than one meter within a particular premises</h5> */}
												</div>
												<div className="row" id="accountsRow" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
													<div className="col-md-6">
														<div className="input-group">
															<div className="form-group">
																<label className="control-label">
																	Basic Load Declaration (Subjected to Inspection)
																</label>
																<div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, fontWeight: 'bold' }}>
																	<div style={{ marginRight: 10, width: 120 }}>
																		Load
																	</div>
																	<div style={{ display: 'flex', flexDirection: 'row', width: 60 }}>
																		Quantity
																	</div>
																	<div style={{ display: 'flex', flexDirection: 'row', width: 120, marginLeft: 50 }}>
																		<button onClick={onAddExtraLoad}>Add Extra Load</button>
																	</div>
																</div>
																{bldOptions && bldOptions.map((item, index) => {
																	return (
																		<BasicLoadDeclarationDefaultItem
																			defaultValue={0}
																			item={item}
																			identifier={index}
																			onChange={(e) => {
																				const _bldOptions = bld;
																				console.log('e _bldOptions', e, bld);
																				let _item = _bldOptions.filter(x => x.identifier === e.identifier);

																				if (_item.length > 0) {
																					let _index = _bldOptions.indexOf(_item[0]);
																					_item[0] = e;
																					bld[_index] = _item[0];
																				} else {
																					_item = e;
																					bld.push(_item);
																				}

																				console.log('e _bldOptions', e, bld);

																				setBLD(bld);
																			}}
																		/>
																	);
																})}
																{bldOption && bldOption.map((item, index) => {
																	return (
																		<BasicLoadDeclarationItem
																			defaultValue={0}
																			item={index}
																			onTitleChange={(e) => {
																				const _bldOptions = bld;
																				// console.log(bld, item);
																				let _item = _bldOptions.filter(x => x.id === e.id);


																				if (_item.length > 0) {
																					let _index = _bldOptions.indexOf(_item[0]);
																					_item[0] = e;
																					bld[_index] = _item[0];
																				} else {
																					_item = e;
																					bld.push(_item);
																				}

																				setBLD(bld);
																			}}
																			onChange={(e) => {

																				const _bldOptions = bld;
																				let _item = _bldOptions.filter(x => x.id === e.id);
																				if (_item.length > 0) {
																					let _index = _bldOptions.indexOf(_item[0]);
																					_item[0] = e;
																					bld[_index] = _item[0];
																				} else {
																					_item = e;
																					bld.push(_item);
																				}

																				setBLD(bld);
																			}}
																		/>
																	);
																})}
															</div>
															{/* <div className="input-group-btn">
															<button id="addmore" className="btn btn-xs btn-danger" ng-click="addAccounts()" type="button">
																<i className="material-icons">add</i> Add more
															</button>
														</div> */}
														</div>
													</div>
												</div>
											</div>
											<div className="tab-pane" id="summary">
												<div className="row">
													{
														referenceId &&
														<div className="col-md-6">
															<div className="pull-left">
																<h4>
																	Reference Id  <span style={{ fontWeight: 'bolder' }}>{referenceId}</span>
																</h4>
															</div>
														</div>
													}
													{
														referenceId && (
															<div className="col-md-6">
																<div className="pull-right">
																	<div className="logos" style={{ padding: "3px", width: "70px", backgroundColor: "white", height: "40px", borderRadius: "4px", boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)" }}>
																		<img
																			width="60"
																			src={`assets/img/${process.env.REACT_APP_APP_LOGO}`}
																			style={{ width: "70px", height: "60px" }}
																		/>
																	</div>
																</div>
															</div>
														)
													}
												</div>
												<div className="row">
													<div className="col-sm-4">
														<h4 className="info-text" style={{ fontWeight: "bolder" }}>Applicant</h4>
														<div className="form-group no-margin">
															<label className="control-label">Name</label>
															<input readonly type="text" className="form-control" ng-model="request.customerName" value={name} />
														</div>
														<div className="form-group no-margin">
															<label className="control-label">Phone Number</label>
															<input readonly type="text" className="form-control" ng-model="request.phone" value={phone} />
														</div>
														<div className="form-group no-margin">
															<label className="control-label">Whatsapp Phone Number</label>
															<input readonly type="text" className="form-control" ng-model="request.altPhone" value={alternatePhone} />
														</div>
														<div className="form-group no-margin">
															<label className="control-label">Email</label>
															<input readonly type="text" className="form-control" ng-model="request.customerEmail" value={email} />
														</div>
													</div>
													<div className="col-sm-4">
														<h4 className="info-text" style={{ fontWeight: "bolder" }}>Account Details</h4>
														<div className="form-group no-margin">
															<label className="control-label">Account Number(s)</label>
															<input readonly type="text" className="form-control" ng-model="accountString" value={accountNo} />
														</div>
														<div className="form-group no-margin">
															<label className="control-label">Name on bill</label>
															<input readonly type="text" className="form-control" ng-model="request.nameOnBill" value={nameOnBill} />
														</div>
														<div className="form-group no-margin">
															<label className="control-label">Address</label>
															<input readonly type="text" className="form-control" ng-model="request.adddress" value={address} />
														</div>

														<div className="row">
															<div className="col-sm-12">
																<div className="form-group no-margin">
																	<label className="control-label">District</label>
																	<input readonly type="text" className="form-control" ng-model="request.district" value={district} />
																</div>
															</div>
															{/* <div className="col-sm-6">
																<div className="form-group no-margin">
																	<label className="control-label">No of Accounts</label>
																	<input readonly type="text" className="form-control" ng-model="request.accountNumber.length" value={1} />
																</div>
															</div> */}
														</div>
														<div className="row">
															<div className="col-sm-4">
																<div className="form-group no-margin">
																	<label className="control-label">State</label>
																	<input readonly type="text" className="form-control" ng-model="request.selectedState.name" value={state} />
																</div>
															</div>
															<div className="col-sm-4">
																<div className="form-group no-margin">
																	<label className="control-label">LGA</label>
																	<input readonly type="text" className="form-control" ng-model="request.lga" value={lga} />
																</div>
															</div>
															<div className="col-sm-4">
																<div className="form-group no-margin">
																	<label className="control-label">Village/Town</label>
																	<input readonly type="text" className="form-control" ng-model="request.villageTown" value={town} />
																</div>
															</div>
														</div>
													</div>
													<div className="col-sm-4">
														<h4 className="info-text" style={{ fontWeight: "bolder" }}>Apartment Details</h4>
														<div className="row">
															<div className="col-sm-12">
																<div className="form-group no-margin">
																	<label className="control-label">Building type</label>
																	<input readonly type="text" className="form-control" ng-model="request.typesOfPremise" value={premiseType} />
																</div>
															</div>
															{/* <div className="col-sm-4">
																<div className="form-group no-margin">
																	<label className="control-label">Flats</label>
																	<input readonly type="text" className="form-control" ng-model="request.noOfFlats" value={noOfFlats} />
																</div>
															</div> */}
														</div>
														<div className="row">
															<div className="col-sm-6">
																<div className="form-group no-margin">
																	<label className="control-label">Use of premises</label>
																	<input readonly type="text" className="form-control" ng-model="request.useOfPremise" value={useOfPremise} />
																</div>
															</div>
															<div className="col-sm-6">
																<div className="form-group no-margin">
																	<label className="control-label">Request type</label>
																	<input readonly type="text" className="form-control"
																		ng-model="request.typeOfConnection" value={typeOfRequest} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-sm-6">
																<div className="form-group no-margin">
																	<label className="control-label">Meter Type Requested</label>
																	<input readonly type="text" className="form-control" ng-model="request.existingConnection" value={meterType} />
																</div>
															</div>
															<div className="col-sm-6">
																<div className="form-group no-margin">
																	<label className="control-label">Old meter No</label>
																	<input readonly type="text" className="form-control" ng-model="request.oldMeterNumber" value={oldMeterNo} />
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-sm-12">
																<div className="form-group no-margin">
																	<label className="control-label">Billing information</label>
																	<input readonly type="text" className="form-control" ng-model="request.currentBillInformation" value={currentBillingInfo} />
																</div>
															</div>
															<div className="col-sm-6">
																<div className="form-group no-margin">
																	<label className="control-label">Applicant</label>
																	<input readonly type="text" className="form-control" ng-model="request.applicant" value={meterRequestBy} />
																</div>
															</div>
															{/* <a href="" target="_self" id="printslip" ng-show="false">print</a> */}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="wizard-footer">
											<div className="pull-right">
												{
													referenceId &&
													<>
														<input type='button' style={{ marginLeft: 5 }} className='btn btn-fill btn-white' name='next' value='Print' onClick={() => { generatePDF() }} />
														<input type='button' className='btn btn-fill btn-white' name='next' value='Restart Process' onClick={() => { window.location.reload() }} />
													</>
												}
												{
													!referenceId &&
													<>
														<input type='button' className='btn btn-next btn-fill btn-success btn-wd' name='next' value='Next' ng-click="createAccountsArray()" />
														<input type='button' onClick={_onSubmit} ng-disabled="!request.accountNumber" id="finishButton" className='btn btn-finish btn-fill btn-success btn-wd' name='finish' value='Submit' />
													</>
												}

											</div>

											<div className="pull-left">
												<input type='button' className='btn btn-previous btn-fill btn-default btn-wd' name='previous' value='Previous' />
											</div>
											<div className="clearfix"></div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer">
					<div className="container text-center">
						&copy; <a href="#">{process.env.REACT_APP_APPLICATION_FOOTER}</a>
					</div>
				</div>
			</div>

		</>
	);
}

export default Form;

const BasicLoadDeclarationDefaultItem = ({ item, defaultValue, onChange, identifier }) => {
	const [title, setTitle] = useState('');
	const [quantity, setQuantity] = useState('');

	const getValue = ({ _value = quantity }) => {
		return { name: item, value: _value, identifier };
	}

	const _onChange = (e) => {
		setQuantity(e.target.value);
		onChange(getValue({ _value: e.target.value }))
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<div style={{ marginRight: 10, width: 120 }}>
				- {item}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', width: 60 }}>
				<input type={"number"} onChange={e => {
					_onChange(e);
				}} style={{ width: 60 }} defaultValue={defaultValue} />
			</div>
		</div>
	);
}

const BasicLoadDeclarationItem = ({ item, defaultValue, onTitleChange, onChange }) => {
	const [title, setTitle] = useState('');
	const [quantity, setQuantity] = useState('');

	const getValue = ({ _name = title, _value = quantity }) => {
		return { name: _name, value: _value, id: item };
	}

	const _onTitleChange = (e) => {
		setTitle(e.target.value);
		onTitleChange(getValue({ _name: e.target.value }))
	}

	const _onChange = (e) => {
		setQuantity(e.target.value);
		onChange(getValue({ _value: e.target.value }))
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<div style={{ marginRight: 10, width: 120 }}>
				<input placeholder={'please specify load'} type={"text"} onChange={e => {
					_onTitleChange(e);
				}} style={{ width: 60 }} />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', width: 60 }}>
				<input type={"number"} onChange={e => {
					_onChange(e);
				}} style={{ width: 60 }} defaultValue={defaultValue} />
			</div>
		</div>
	);
}
